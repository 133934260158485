import React from "react";
import Blog from '../components/molecules/NodeTypes/blog';
import Layout from "../components/templates/Layout";
import {graphql} from "gatsby";

/**
 * @todo fix metatags
 */
const NodeArticle = ({data}) => (
    <Layout
        lang={data.drupal.blogDetail.langcode}
        class={'blog'}
        activeMenu={'blog'}
        activeNid={data.drupal.blogDetail.entityId}
        metatags={data.drupal.blogDetail.url.metatags}
        title={'Blog: ' + data.drupal.blogDetail.title}
    >
        <Blog nodeContent={data.drupal.blogDetail} />
    </Layout>
);

export default NodeArticle;

export const query = graphql`
query blog($id: ID!, $language: Drupal_Langcode!) {
    drupal {
        blogDetail: entityById(id: $id, langcode: $language, entityType: NODE) {
            entityId: id
            ... on Drupal_NodeArticle {
                nid
                title
                body 
                internalId: nid
                path {
                    alias
                }
                langcode
                url {
                    ... on Drupal_EntityUrl {
                        metatags {
                            tag
                            attributes {
                                key
                                value
                            }
                        }
                    }
                }
            }
        }
    }
}
`;
