import React, {Component} from "react";
import Button from "../../atoms/Buttons/Button";
import {trackCustomEvent} from "gatsby-plugin-google-analytics";
import {currentLanguageByPath, getTranslations, replaceAll} from "../../../helpers/MainHelper";

class Blog extends Component {
    render() {
        const i18next = getTranslations(currentLanguageByPath());
        let content = '';

        if (this.props.nodeContent.body !== null) {
            content += this.props.nodeContent.body;
        }

        content = replaceAll(content,'/sites/default/files', process.env.DRUPAL_URL + '/sites/default/files');

        let returnLink = '/blog';
        if (currentLanguageByPath() === 'en') {
            returnLink = '/en/blog';
        }

        return <>
            <h1>{this.props.nodeContent.title}</h1>
            <div dangerouslySetInnerHTML={{__html:content}} />
            <Button
                onClick={e => {
                    // Lets track that custom click
                    trackCustomEvent({
                        // string - required - The object that was interacted with (e.g.video)
                        category: "Back to blog list",
                        // string - required - Type of interaction (e.g. 'play')
                        action: "click",
                    })
                    return true;
                }}
                link={returnLink}
                label={i18next.t('back_blog')}
                class={'button-orange'}
            />
        </>
    }
}

export default Blog;
